import React from "react";
import { SEO, MarkdownContent } from "@bluefin/components";
import { Grid, Segment, Embed } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class MondaySpecialsPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <Grid
          className={"custom-page-monday-specials custom-page-grid"}
          style={{}}
          stackable={true}
        >
          <Grid.Row
            className={""}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
            columns={1}
          >
            <Grid.Column
              className={""}
              style={{ padding: 16 }}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "02667245-cba1-4926-ac19-359da7c1c3bf",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={""} style={{ padding: 16 }} columns={1}>
            <Grid.Column className={""} style={{ padding: 16 }}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <React.Fragment
                  key={"JSON__b2766b8e-f010-4b87-8e24-9328605ca1ee"}
                >
                  <Grid
                    stackable={true}
                    className={"component-section-container"}
                    verticalAlign={"middle"}
                    textAlign={"center"}
                  >
                    <Grid.Column width={16} textAlign={"center"}>
                      <Embed
                        url={
                          "https://player.vimeo.com/video/944607677?badge=0&autopause=0&player_id=0&app_id=58479"
                        }
                        autoplay={true}
                        defaultActive={true}
                        className={""}
                        allow={
                          "autoplay; fullscreen; picture-in-picture; clipboard-write"
                        }
                      />
                    </Grid.Column>
                  </Grid>
                </React.Fragment>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 24253 }) {
      title
      description
      components {
        files {
          file
        }
        order
        fastId
        componentIdentifier
        contentNodes {
          content
        }
      }
    }
  }
`;
